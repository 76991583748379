































































import Vue from 'vue';
import RecommendPrice from '@/views/projects/detail/recommendPrice/index.vue';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    scopedSlots: { customRender: 'fxCode' },
    key: 'fxCode',
    align: 'center',
    width: 150,
  },
  {
    title: '项目名称',
    dataIndex: 'fxName',
    key: 'fxName',
    align: 'center',
  },
  {
    title: '项目特征',
    dataIndex: 'projectAttr',
    scopedSlots: { customRender: 'projectAttr' },
    key: 'projectAttr',
    width: '220px',
    align: 'left',
  },
  {
    title: '计量单位',
    dataIndex: 'unit',
    scopedSlots: { customRender: 'unit' },
    key: 'unit',
    align: 'center',
  },
  {
    title: '工程数量',
    dataIndex: 'quantityStr',
    key: 'quantityStr',
    align: 'center',
  },
  {
    title: '综合单价(元)',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
    key: 'price',
    align: 'center',
  },
  {
    title: '合计(元)',
    dataIndex: 'total',
    scopedSlots: { customRender: 'total' },
    key: 'total',
    align: 'center',
  },
];

import mixin from './mixin';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'subItemEngineering',
  mixins: [mixin],
  data() {
    return {
      columns,
      record: null,
      recommendVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      'isPriceAdjustmentOrValuation',
      'combinedPriceStatus',
      'listGenerating',
      'isPriceAdjustment',
    ]),
  },
  components: {
    RecommendPrice,
  },
  methods: {
    // 项目编码点击事件
    handleCode(record: any) {
      this.recommendVisible = true;
      this.record = record;
      if (record.biddingType === 0) {
        this.$store.commit('SET_RELEVANCY', record.fxCode);
        this.$store.commit('SET_TENDERRELEVANCY', '');
      } else {
        this.$store.commit('SET_RELEVANCY', '');
        this.$store.commit('SET_TENDERRELEVANCY', record.sequenceNbr);
      }
    },
    handleTips() {
      if (this.combinedPriceStatus) {
        this.$message.warning('项目正在组价中');
        return;
      }
      if (this.isPriceAdjustmentOrValuation) {
        this.$message.warning('项目工程量清单重新计价中');
        return;
      }
      if (this.isPriceAdjustment) {
        this.$message.warning('项目工程量清单存在人工调价未完成项');
        return;
      }
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
    },
  },
});
