var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invitation-item"},[_c('a-table',{ref:"table",style:(_vm.componentsStyle),attrs:{"rowKey":"sequenceNbr","columns":_vm.columns,"data-source":_vm.tableData,"pagination":false,"scroll":_vm.tableScroll,"loading":_vm.loading,"bordered":""},scopedSlots:_vm._u([{key:"fxCode",fn:function(text, record){return [(
          record.dispNo &&
          _vm.currentTreeInfo.bidSequenceNbr &&
          !_vm.isPriceAdjustmentOrValuation &&
          !_vm.isPriceAdjustment &&
          !_vm.combinedPriceStatus &&
          !_vm.listGenerating
        )?_c('div',[_c('span',{staticClass:"selected",on:{"click":function($event){return _vm.handleCode(record)}}},[_vm._v(_vm._s(record.fxCode)),(record.biddingType === 1 && record.extend3 === '空')?_c('span',{staticClass:"identification"},[_vm._v("空")]):_vm._e(),(record.biddingType === 1 && Number(record.extend3) > 0)?_c('span',{staticClass:"identification identification-num"},[_vm._v("+"+_vm._s(record.extend3))]):_vm._e()])]):_c('div',{staticClass:"selected",on:{"click":function($event){return _vm.handleTips()}}},[_c('span',[_vm._v(_vm._s(record.fxCode))])])]}},{key:"projectAttr",fn:function(text, record){return [_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(record.projectAttr))])]}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('span',{staticClass:"icon-custom"})])]),_c('recommend-price',{attrs:{"visible":_vm.recommendVisible,"record":_vm.record,"type":1},on:{"update:visible":function($event){_vm.recommendVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }