
























































import Vue from 'vue';
import tabs from '../tabs.vue';
import subItemEngineering from '@/views/projects/detail/invitationToTender/subItemEngineering.vue';
import priceMeasuresItem from '@/views/projects/detail/invitationToTender/priceMeasuresItem.vue';
import totalMeasuresItem from '@/views/projects/detail/invitationToTender/totalMeasuresItem.vue';
import otherItem from '@/views/projects/detail/invitationToTender/otherItem.vue';
import provisionalSumItem from '@/views/projects/detail/invitationToTender/provisionalSumItem.vue';
import provisionalEstimateItem from '@/views/projects/detail/invitationToTender/provisionalEstimateItem.vue';
import generalContractingItem from '@/views/projects/detail/invitationToTender/generalContractingItem.vue';
import datallerItem from '@/views/projects/detail/invitationToTender/datallerItem.vue';
import mainMaterial from '@/views/projects/detail/invitationToTender/mainMaterial.vue';
import supplyMaterial from '@/views/projects/detail/invitationToTender/supplyMaterial.vue';
import * as api from '@/api/projectDetail';
import { mapGetters } from 'vuex';
import ImportCompany from '@/views/projects/detail/lackdata/import-company.vue';
import NoImportInfo from '@/views/projects/detail/lackdata/no-import-info.vue';
import UnitTabs from '@/views/projects/unitTabs.vue';
import { setTableHeaderWidth } from '@/utils';
interface resData {
  [key: string]: any;
}
interface tabsData {
  description: string;
  extend1: string;
  extend2: string;
  extend3: string;
  fbName: string;
  fromType: string;
  itemCategory: string;
  recDate: string;
  recStatus: string;
  recUserId: string;
  sequenceNbr: string;
  xmLever: string;
  xmType: string;
  name: string;
}
export default Vue.extend({
  name: 'invitationToTender',
  components: {
    UnitTabs,
    NoImportInfo,
    ImportCompany,
    tabs,
    subItemEngineering,
    priceMeasuresItem,
    totalMeasuresItem,
    otherItem,
    provisionalSumItem,
    provisionalEstimateItem,
    generalContractingItem,
    datallerItem,
    mainMaterial,
    supplyMaterial,
  },
  data() {
    return {
      tabs: [],
      componentName: 'subItemEngineering',
      componentHeight: 0,
      tableHeadHeight: 0,
      compoundMode: {
        '01': 'subItemEngineering',
        '0202': 'priceMeasuresItem',
        '0203': 'totalMeasuresItem',
        '0301': 'otherItem',
        '0302': 'provisionalSumItem',
        '0303': 'provisionalEstimateItem',
        '0304': 'generalContractingItem',
        '0305': 'datallerItem',
        '0701': 'mainMaterial',
        '0702': 'supplyMaterial',
      } as Record<string, string>,
      xmType: '',
      itemCategory: '',
      fbSequenceNbr: '',
      unitSequenceNbr: '',
    };
  },
  computed: {
    ...mapGetters([
      'sequenceNbr',
      'currentTreeInfo',
      'isUnfold',
      'isTenderUnfold',
      'isRetractUnfold',
      'relevancy',
    ]),
    componentsStyle(): { height: string } {
      let height = 0;
      if (this.relevancy) {
        height = window.innerHeight * 0.6 - 64 - 34 - 24 - 52; // 空数据的样式高度 = 60vh - 组价推荐标题高度 - padding(24) - 无数据的padding和border(34)- 表头高度 -
      } else {
        height = this.componentHeight - this.tableHeadHeight - 34 - 2; // 空数据的样式高度 = 表格总高度 - 表头高度 - 样式内边距 - border
      }
      return {
        height: height + 'px',
      };
    },
  },
  created() {
    console.log('ifChildrenMissing', this.currentTreeInfo.ifChildrenMissing);
    if (!this.currentTreeInfo.ifChildrenMissing && this.sequenceNbr) {
      this.getCategoryByUnitSequenceNbr();
    }
  },
  watch: {
    sequenceNbr: function (val, oldVal) {
      if (val === oldVal) return '';
      this.componentName = 'subItemEngineering';
      this.getCategoryByUnitSequenceNbr();
    },
    isUnfold: function (val) {
      this.$store.commit('SET_ISUNFOLD', val);
      this.setComponentHeight();
      this.getTableHeaderWidth();
    },
    isTenderUnfold: function (val) {
      this.setComponentHeight();
    },
  },
  mounted() {
    if (!this.sequenceNbr) {
      this.setComponentHeight();
    }
    // 单项工程跟单位工程的一键组价状态不关联,故单项页面修改了状态,进单位页面需重置，避免状态不更新，tabs无法重置宽度
    if (!this.isUnfold || !this.isTenderUnfold) {
      this.$store.commit('SET_ISRETRACTUNFOLD', !this.isRetractUnfold);
    }
    window.addEventListener('resize', this.getTableHeaderWidth);
  },
  methods: {
    setComponentHeight() {
      this.$nextTick(() => {
        const projectEl = this.$refs.invitationToTender as HTMLDivElement;
        const totalHeight = projectEl.clientHeight;
        const tenderHeaderEl = this.$refs.tenderHeader as HTMLDivElement;
        const tenderHeaderHeight = tenderHeaderEl.clientHeight;
        let tabHeight = 0;
        console.log(
          '=======',
          this.componentHeight,
          totalHeight,
          tenderHeaderHeight,
          tabHeight
        );
        if (tabHeight > 0) {
          this.componentHeight = totalHeight - tenderHeaderHeight - 10; // 总招标的高度-头部标题高度-tab高度-margin(10)
        } else {
          this.componentHeight = totalHeight - tenderHeaderHeight - tabHeight; // 总招标的高度-头部标题高度-tab高度
        }
        const tableEl = document.querySelector(
          '.ant-table-thead'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.invitation-to-tender');
    },
    updateTab(value: string) {
      this.componentName = value;
      let obj = this.tabs.filter((item: tabsData) => item.name === value)[0];
      this.xmType = (obj as tabsData).xmType;
      this.itemCategory = (obj as tabsData).itemCategory;
      this.fbSequenceNbr = (obj as tabsData).sequenceNbr;
      this.$store.commit('SET_RELEVANCY', '');
    },
    /**
     * 获取招标tab菜单
     */
    getCategoryByUnitSequenceNbr() {
      let apiData = {
        unitSequenceNbr: this.sequenceNbr,
        biddingType: 0, // 0招标 1 投标
      };
      api
        .coccMtdsResource_1_project_unitsCategory(apiData)
        .then((res: resData) => {
          if (res.code) {
            for (let i in this.compoundMode) {
              res.result.map((item: any) => {
                if (
                  item.xmType + (item.itemCategory ? item.itemCategory : '') ===
                  i
                ) {
                  item.name = this.compoundMode[i];
                  item.label = item.fbName;
                }
              });
            }
            this.xmType = res.result[0].xmType;
            this.itemCategory = res.result[0].itemCategory;
            this.fbSequenceNbr = res.result[0].sequenceNbr;
            this.tabs = res.result;
            this.componentName = res.result[0].name;
            this.setComponentHeight();
          }
        });
    },
    /**
     * 展开收缩方法
     */
    handleOptions() {
      this.$store.commit('SET_ISUNFOLD', !this.isUnfold);
      if (this.isUnfold) {
        this.$store.commit('SET_ISTENDERUNFOLD', true);
        this.$store.commit('SET_ISRETRACTUNFOLD', true);
      } else {
        this.$store.commit('SET_ISRETRACTUNFOLD', false);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getTableHeaderWidth, false);
  },
});
