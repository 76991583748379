




























import Vue from 'vue';
export default Vue.extend({
  name: 'unitTabs',
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    updateTab(value: string) {
      this.$emit('change', value);
    },
  },
});
