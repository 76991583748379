





























import Vue from 'vue';
import mixin from './mixin';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '项目名称',
    dataIndex: 'extraName',
    key: 'extraName',
    align: 'center',
  },
  { title: '金额(元)', dataIndex: 'total', key: 'total', align: 'center' },
];

export default Vue.extend({
  name: 'otherItem',
  mixins: [mixin],
  data() {
    return {
      columns,
    };
  },
});
