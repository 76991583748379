




































































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'unitTabs',
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeKey: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'isUnfold',
      'isTenderUnfold',
      'isRetractUnfold',
      'readOnly',
    ]),
    tabsStyle(): Record<string, string> {
      return {
        '-webkit-transform': `translateX(${this.tabLeft}px)`,
        transform: `translateX(${this.tabLeft}px)`,
      };
    },
    tabScrollStyle(): Record<string, string> {
      const clientWidth = this.optListRef.clientWidth;
      return {
        width: `calc(100% - 40px - ${clientWidth}px)`,
      };
    },
    isMoveRight(): boolean {
      return !(Math.abs(this.tabLeft) >= this.maxLeft);
    },
    isMoveLeft(): boolean {
      return Math.abs(this.tabLeft) > 0;
    },
    tabWidthList(): number[] {
      const list = this.tabAnimationRef.querySelectorAll('.tab');
      let widthList: number[] = [];
      list.forEach((item) => {
        widthList.push(item.clientWidth);
      });
      return widthList;
    },
  },
  data() {
    const optListRef: HTMLDivElement = document.createElement('div');
    const tabScrollRef: HTMLDivElement = document.createElement('div');
    const tabAnimationRef: HTMLDivElement = document.createElement('div');
    return {
      tabLeft: 0, // 移动距离
      optListRef, // 左右移动操作ref
      tabScrollRef, // 可显示tab的ref
      tabAnimationRef, // 总tab的ref
      currentMoveIndex: 0, // 移动tab的索引
      checkedKey: '', // 选中的key
      bottomLineWidth: 0,
      maxLeft: 0,
    };
  },
  watch: {
    tabs: {
      handler: function (val) {
        if (!val.length) return '';
        this.$nextTick(() => {
          this.tabAnimationRef = this.$refs.tabAnimation as HTMLDivElement;
          this.setMaxLeft();
        });
      },
      immediate: true,
    },
    activeKey: function (val, oldVal) {
      if (val === oldVal) return;
      if (val) {
        this.checkedKey = this.activeKey;
      }
    },
    isRetractUnfold: function (val) {
      this.tabScrollRef = this.$refs.tabScroll as HTMLDivElement;
      this.setMaxLeft();
      this.leftmostMove();
    },
  },
  created() {
    this.checkedKey = this.activeKey;
    this.$nextTick(() => {
      this.optListRef = this.$refs.optList as HTMLDivElement;
      this.tabScrollRef = this.$refs.tabScroll as HTMLDivElement;
    });
  },
  methods: {
    setMaxLeft() {
      setTimeout(() => {
        // 获取optListRef最终宽度
        const maxClientWidth = this.tabAnimationRef.clientWidth;
        const clientWidth = this.tabScrollRef.clientWidth;
        this.maxLeft = maxClientWidth - clientWidth;
      }, 20);
    },
    calcBottomLineWidth(index) {
      let width = 0;
      for (let i = 0; i < index; i++) {
        width += this.tabWidthList[i];
      }
      width += this.tabLeft;
      this.bottomLineWidth = width > 0 ? width : 0;
    },
    updateTab(value: string, index) {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.checkedKey = value;
      this.calcBottomLineWidth(index);
      this.$emit('change', value);
    },
    /**
     * 移动到最左
     */
    leftmostMove() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isMoveLeft) return false;
      this.tabLeft = 0;
      this.currentMoveIndex = 0;
    },
    /**
     * 向左移动
     */
    leftMove() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isMoveLeft) return false;
      this.currentMoveIndex--;
      if (this.currentMoveIndex <= 0) {
        this.tabLeft = 0;
        return;
      }
      let left = 0;
      for (let i = 0; i < this.currentMoveIndex; i++) {
        left += this.tabWidthList[i];
      }
      this.tabLeft = Number(`-${left}`);
    },
    /**
     * 向右移动
     */
    rightMove() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isMoveRight) return false;
      let left = 0;
      for (let i = 0; i <= this.currentMoveIndex; i++) {
        left += this.tabWidthList[i];
      }
      this.tabLeft = Number(`-${left}`);
      this.currentMoveIndex++;
    },
    /**
     * 移动到最右
     */
    rightmostMove() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isMoveRight) return false;
      this.tabLeft = Number(`-${this.maxLeft}`);
      const tabWidthList = this.tabWidthList;
      let left = 0;
      for (let i = 0, len = tabWidthList.length; i < len; i++) {
        left += tabWidthList[i];
        if (left >= Math.abs(this.tabLeft)) {
          this.currentMoveIndex = i;
          return;
        }
      }
    },
    getPopupContainer() {
      return document.getElementById('ysf_body');
    },
  },
});
