































import Vue from 'vue';
import mixin from './mixin';

const columnConfig = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    scopedSlots: { customRender: 'fxCode' },
    key: 'fxCode',
    align: 'center',
  },
  { title: '项目名称', dataIndex: 'fxName', key: 'fxName', align: 'center' },
  { title: '金额(元)', dataIndex: 'total', key: 'total', align: 'center' },
];

export default Vue.extend({
  name: 'priceMeasuresItem',
  mixins: [mixin],
  data() {
    return {
      columnConfig,
    };
  },
});
