






























import Vue from 'vue';
import * as api from '@/api/Import';
import { checkFileType } from '@/utils/index';
import AliOss from '@/utils/alioss';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default Vue.extend({
  components: {
    resultCheck: () => import('@/views/projects/popup/result-check.vue'),
  },
  data() {
    return {
      filepath: '',
      showStatus: false, // 隐藏结果校验
      file: null,
      paramsFileData: '',
    };
  },
  methods: {
    beforeUpload(file) {
      const isType = checkFileType(file.name, 'xlsx', 'xls');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      return isType;
    },
    /**
     * @description: 上传文件
     * @param {*} params
     * @return {*}
     */
    async uploadFile(params: any) {
      const isType = checkFileType(params.file.name, 'xlsx', 'xls');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      this.file = params.file;
      if (this.file) {
        this.paramsFileData = await new AliOss().upload(this.file);
        this.paramsFileData && (this.showStatus = true);
      }
    },
    /**
     * @description: 导入单位项目
     * @param {*}
     * @return {*}
     */
    handleChange(info: FileInfo) {
      if (info.file?.status === 'error') {
        this.$message.error(`${info.file.name} 导入失败`);
        return;
      }
      if (info.file?.status && info.file?.status !== 'uploading') {
        const { status, result, message } = info.fileList[
          info.fileList.length - 1
        ]?.response as any;
        if ([200].includes(+status)) {
          if (result.code === 0) {
            this.showStatus = true;
            this.filepath = result.data;
          } else {
            this.$message.error(result.msg);
          }
        } else {
          this.$message.error(message);
        }
      }
    },
  },
});
