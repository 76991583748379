<!--
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2021-10-22 14:36:31
 * @LastEditors: liuxia
 * @LastEditTime: 2021-10-28 15:46:19
-->
<template>
  <div class="no-import-info">
    <img src="@/assets/img/noExport.png" alt />
    <div class="text">暂无数据</div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'no-import-info',
});
</script>

<style lang="less" scoped>
.no-import-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 237px;
    height: 158px;
  }
  .text {
    margin-top: 13px;
    font-size: 14px;
    color: #c0c4cc;
  }
}
</style>
